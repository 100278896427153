import {useEffect, useState} from 'react'
import CareerService from "../services/CareerService";
import {useSearchParams} from "react-router-dom";

const Career = () => {
    const [data, setData] = useState(null)
    const [loading, isLoading] = useState(true)
    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        async function runPromise() {
            let response = await CareerService.getData(searchParams.get('career'))
            setData(JSON.parse(response['data']))
            console.log(JSON.parse(response['data']))
            isLoading(false)
        }

        runPromise().then(r => {
        })
    }, [])

    if (loading) return null;

    return (
        <>
            <div class="container mx-auto pt-10">
                <div class=" text-3xl  font-extrabold text-lime-900">
                    {data['title']}
                </div>
                <div class="pt-5">
                    <button
                        class=" text-blue-600 font-bold"
                        data-bs-toggle="modal"
                        data-bs-target="#alsoModal"
                    >
                        Also called:
                    </button>
                    {" "}
                    <span>
                        {data['alsoCalled']}
                    </span>
                </div>
                <div class="grid grid-cols-3 gap-4 pt-5">
                    <div class="mr-10">
                        <div class="font-bold	">What they do :</div>
                        {data['header']['whatTheyDo']}
                    </div>
                    <div class="col-span-2">
                        <div class="font-bold">On the job, you would:</div>
                        <ul class="list-disc pl-4 subsec">
                            {data['header']['onTheJob'].map((v) => {
                                return <li>
                                    {v}
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
                <div class="flex pt-10 justify-around">
                    <div class="h-auto border-solid border rounded-lg divide-blue-200 px-2 py-4">
                        <h2 class="font-bold text-2xl text-violet-600">
                            <span>KNOWLEDGE</span>
                        </h2>
                        {data['report']['knowledge'].map((header) => {
                            return <>
                                <h3 className="text-violet-600 font-bold pt-2">
                                    {header.title}
                                </h3>
                                <ul class="list-disc pl-4 subsec">
                                    {header['list'].map((list) => {
                                        return <li> {list} </li>
                                    })}
                                </ul>
                            </>
                        })}
                    </div>
                    <div class="h-auto border-solid border rounded-lg divide-blue-200 px-2 py-4	 ">
                        <h2 class=" font-bold text-2xl  text-yellow-600	">
                            <span>SKILLS</span>
                        </h2>
                        {data['report']['skills'].map((header) => {
                            return <>
                                <h3 class=" text-yellow-600	font-bold pt-2">
                                    {header.title}
                                </h3>
                                <ul class="list-disc pl-4 subsec">
                                    {header['list'].map((list) => {
                                        return <li> {list} </li>
                                    })}
                                </ul>
                            </>
                        })}
                    </div>
                    <div class="h-auto border-solid border rounded-lg divide-blue-200 px-2 py-4	 ">
                        <h2 class=" font-bold text-2xl  text-cyan-600	">
                            <span>ABILITIES</span>
                        </h2>
                        {data['report']['abilities'].map((header) => {
                            return <>
                                <h3 class="text-cyan-600 font-bold pt-2">
                                    {header.title}
                                </h3>
                                <ul class="list-disc pl-4 subsec">
                                    {header['list'].map((list) => {
                                        return <li> {list} </li>
                                    })}
                                </ul>
                            </>
                        })}
                    </div>
                </div>

                <div class="flex justify-around pt-10">
                    <div class="w-2/3 pr-10">
                        <div
                            class="h-auto w-full border-solid border rounded-lg divide-blue-200 px-2 py-4	min-h-[300px] ">
                            <h2 class=" font-bold text-2xl  text-rose-300	">
                                <span>PERSONALITY</span>
                            </h2>
                            <p class="pt-5">
                                {data['report']['personality']['activities']}
                            </p>

                            <ul class="list-disc pl-4 subsec text-rose-300 font-bold">
                                {data['report']['personality']['needs'].map((need) => {
                                    return <li> {need} </li>
                                })}
                            </ul>
                        </div>
                    </div>
                    <div class="w-1/3">
                        <div
                            class="h-auto w-full border-solid border rounded-lg divide-blue-200 px-2 py-4 min-h-[300px]	 ">
                            <h2 class=" font-bold text-2xl  text-lime-600	">
                                <span>TECHNOLOGY</span>
                            </h2>
                            {data['report']['technology'].map((header) => {
                                return <>
                                    <h3 class="text-cyan-600 font-bold pt-2">
                                        {header.title}
                                    </h3>
                                    <ul class="list-disc pl-4 subsec">
                                        {header['list'].map((list) => {
                                            return <li> {list} </li>
                                        })}
                                    </ul>
                                </>
                            })}
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Career;
