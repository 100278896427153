import {Navigate, useNavigate} from "react-router-dom";

const ContinueQuiz = () => {
    const navigate = useNavigate();

    if (localStorage.answersState === undefined) {
        return <Navigate to="/quiz"/>
    }

    function onContinue() {
        navigate('/quiz');
    }

    function onRestart() {
        delete localStorage.answersState;
        navigate('/quiz');
    }

    return (
        <div className="rounded-sm bg-white  flex flex-row items-start justify-center p-10 box-border ">
            <div
                className="rounded-sm bg-white shadow-[0px_22.898128509521484px_45.8px_-11.45px_rgba(52,_41,_39,_0.08)] w-[885px] flex flex-col items-center justify-start p-10 box-border gap-[20px]">
                <div
                    className=" flex flex-col items-center justify-between font-black text-primaty-cyan-dark text-3xl	">
                    <div className="flex flex-row items-center justify-center gap-[10px]">
                        <div className="relative tracking-[0.1em] leading-[100%] ">
                            CONTINUE WHERE YOU LEFT OFF?
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-center gap-[10px] my-10">
                        <button
                            onClick={onContinue}
                            className="group relative h-12 w-48 overflow-hidden rounded-2xl bg-green-500 text-lg font-bold text-white">
                            Continue
                            <div
                                className="absolute inset-0 h-full w-full scale-0 rounded-2xl transition-all duration-300 group-hover:scale-100 group-hover:bg-white/30"></div>
                        </button>
                        <button
                            onClick={onRestart}
                            className="group relative h-12 w-48 overflow-hidden rounded-2xl bg-red-500 text-lg font-bold text-white">
                            Reset
                            <div
                                className="absolute inset-0 h-full w-full scale-0 rounded-2xl transition-all duration-300 group-hover:scale-100 group-hover:bg-white/30"></div>
                        </button>
                    </div>
                </div>
                <div/>
            </div>
        </div>
    );
};

export default ContinueQuiz;
