import {useEffect, useState} from 'react'
import AuthService from '../services/AuthService'
import {Navigate} from 'react-router-dom'


const PrivateRoute = ({children}) => {
    const [data, setData] = useState(null)
    const [loading, isLoading] = useState(true)

    useEffect(() => {
        async function runPromise() {
            let response = await AuthService.isLoggedIn();
            setData(response)
            isLoading(false)
        }

        runPromise().then(r => {
        })
    }, [])

    if (loading) return null;
    return data ? children : <Navigate to="/login"/>;
}


export default PrivateRoute