import React, {useEffect, useState} from 'react';
import '../css/Quiz.css'
import {useNavigate} from "react-router-dom";
import rawQuestions from '../data/questions.json';
import CareerService from "../services/CareerService";
import AuthService from "../services/AuthService";

function App() {
    var answersState;
    var userAnswers;
    if (localStorage.answersState === undefined) {
        userAnswers = new Array(142).fill(0);
        answersState = {
            current: 0,
            answers: userAnswers,
            timestamp: Date.now()
        }
    } else {
        answersState = JSON.parse(localStorage.answersState);
        userAnswers = answersState.answers;
        console.log(userAnswers)
    }
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(answersState.current);
    const [selectedOption, setSelectedOption] = useState(answersState.answers[answersState.current]);
    const navigate = useNavigate();

    answersState.questionsLength = 0;
    const questions = [];
    for (let question of rawQuestions['questions']) {
        for (let description of question.description) {
            questions.push({
                title: question.title,
                description: description
            })
            break;
        }
    }

    const loadQuestion = () => {
        const currentQuestion = rawQuestions[currentQuestionIndex];
        const handleSelect = (event) => {
            const value = parseInt(event.target.value, 10);
            setSelectedOption(value);
            const buttons = document.getElementsByClassName('select-button')
            for (let i = 0; i < buttons.length; i++) {
                const button = buttons[i]
                if (button.value !== event.target.value) {
                    button.style['color'] = 'black'
                    button.style['borderColor'] = 'black'
                    button.style['borderWidth'] = '1px'
                    button.style['fontWeight'] = 'normal'
                } else {
                    button.style['color'] = 'red'
                    button.style['borderColor'] = 'red'
                    button.style['borderWidth'] = '2px'
                    button.style['fontWeight'] = 'bold'
                }
            }
        };

        return (
            <div>
                <div className="w-[510px] h-[81px] p-10 bg-zinc-100 rounded-lg justify-center flex items-center ">
                    <p>{questions[currentQuestionIndex].description}</p>
                </div>
                <div className="options">
                    <div className="max-w-lg mx-auto">
                        <div className="inline-flex shadow-sm rounded-md" role="group">
                            <button
                                value={1}
                                onClick={handleSelect}
                                type="button"
                                className="select-button rounded-l-lg border border-black bg-white text-sm font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-blue-700">
                                1
                            </button>
                            <button
                                value={2}
                                onClick={handleSelect}
                                type="button"
                                className="select-button border border-black bg-white text-sm font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-blue-700">
                                2
                            </button>
                            <button
                                value={3}
                                onClick={handleSelect}
                                type="button"
                                className="select-button border border-black bg-white text-sm font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-blue-700">
                                3
                            </button>
                            <button
                                value={4}
                                onClick={handleSelect}
                                type="button"
                                className="select-button border border-black bg-white text-sm font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-blue-700">
                                4
                            </button>
                            <button
                                value={5}
                                onClick={handleSelect}
                                type="button"
                                className="select-button rounded-r-md border border-black bg-white text-sm font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-blue-700">
                                5
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    const submitAnswers = async () => {
        if (selectedOption) {
            userAnswers[currentQuestionIndex] = selectedOption

            const answersState = {}
            answersState.answers = userAnswers;
            answersState.current = currentQuestionIndex + 1;
            answersState.timestamp = Date.now();
            localStorage.answersState = JSON.stringify(answersState);

            if (currentQuestionIndex < questions.length - 1) {

                setCurrentQuestionIndex(currentQuestionIndex + 1);
                setSelectedOption('');

                const buttons = document.getElementsByClassName('select-button')
                for (let i = 0; i < buttons.length; i++) {
                    const button = buttons[i]
                    button.style['color'] = 'black'
                    button.style['borderColor'] = 'black'
                    button.style['borderWidth'] = '1px'
                    button.style['fontWeight'] = 'normal'
                }

            } else {
                let isLoggedIn = await AuthService.isLoggedIn();
                if (isLoggedIn) {
                    let result = await CareerService.getPrediction(userAnswers);
                    delete localStorage.answersState
                    navigate('/result', {state: {answers: userAnswers, result: result}});
                } else {
                    navigate('/register');
                }
            }
        } else {
            alert('Vui lòng chọn một trong 5 lựa chọn.');
        }
    };

    return (
        <div className="App flex flex-col justify-center items-center">
            <div
                className="mb-10 w-[800px] bg-white rounded-[14px] shadow justify-start items-center  inline-flex">
                <div className="grow shrink basis-0 flex-col justify-start items-start  inline-flex">
                    <div className="self-stretch flex-col justify-start items-start  flex">
                        <h1
                            className="my-3 self-stretch text-4xl font-bold font-['Montserrat'] leading-snug">
                            Quiz #{currentQuestionIndex + 1}
                        </h1>
                        <div
                            className="my-3 self-stretch text-neutral-500 text-base font-semibold font-['Montserrat'] leading-snug">
                            Thang điểm từ 1 đến 5, trong đó:
                            <br/>
                            1: Hoàn toàn không đồng ý
                            <br/>
                            5: Hoàn toàn đồng ý
                        </div>
                    </div>

                </div>
            </div>
            <div
                className="self-stretch text-black text-[32px] font-bold font-['Dela Gothic One'] leading-loose">
                {questions[currentQuestionIndex].title}
            </div>
            {loadQuestion()}
            <div className=" w-32 py-1.5 bg-sky-700 rounded-[100px] hover:bg-red-700 duration-200">
                <button onClick={submitAnswers}
                        className="text-white text-base font-bold font-['Montserrat'] leading-none ">Continue
                </button>
            </div>
        </div>

    );
}

export default App;
