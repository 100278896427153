export default class CareerService {
    static async getPrediction(answers) {
        const url = process.env.REACT_APP_SERVER_HOST + '/career/predict/';
        const response = await fetch(url,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    answers: answers
                }),
                credentials: 'include'
            });
        return await response.json();
    }

    static async getData(career) {
        const url = process.env.REACT_APP_SERVER_HOST + '/career/data/?career=' + career;
        const response = await fetch(url,
            {
                method: "GET",
                credentials: 'include'
            });
        return await response.json();
    }
}