import React, {useEffect, useState} from 'react'
import AuthService from '../services/AuthService'
import {Navigate} from 'react-router-dom'

const Logout = () => {
    const [loading, isLoading] = useState(true)

    useEffect(() => {
        async function runPromise() {
            await AuthService.logOut();
            isLoading(false)
        }

        runPromise().then(r => {
        })
    }, [])

    if (loading) return null
    return <Navigate to="/"/>;

}
export default Logout