import {Navigate, useLocation, useNavigate} from "react-router-dom";

const Tests = () => {
    const handleContinueClick = () => {
        window.location.href = "/after-quiz";
    };
    const {state} = useLocation();
    const navigate = useNavigate();
    if (state === null) return <Navigate to="/intro"/>;

    const userAnswers = state.answers;
    const userResult = state.result;

    const careerKeysSorted = Object.keys(userResult).sort(function (a, b) {
        return userResult[b] - userResult[a]
    })

    const subMajorElements = [];
    var subMajorElementsLength;
    if (careerKeysSorted.length < 5) {
        subMajorElementsLength = careerKeysSorted.length;
    } else {
        subMajorElementsLength = 5
    }

    function formatMajor(str) {
        const splitStr = str.toLowerCase().split('_');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    for (let i = 1; i < subMajorElementsLength; i++) {
        subMajorElements.push(
            <div className="text-black text-xl font-normal font-['Dela Gothic One']">
                {formatMajor(careerKeysSorted[i])} - {userResult[careerKeysSorted[i]]}%
            </div>
        )
    }
    return (
        <div className="flex justify-center items-center pt-10">
            <div
                className="flex flex-col items-center w-[999px] h-[450px] pt-12 bg-white rounded-[14px] shadow gap-2.5">
                <div className="text-black text-5xl font-normal font-['Dela Gothic One'] leading-[48px]">
                    Bạn hợp với nghề nghiệp
                </div>
                <div className="mt-5 mb-5 text-black text-5xl font-bold font-['Dela Gothic One'] leading-[48px]">
                    {formatMajor(careerKeysSorted[0])} - {userResult[careerKeysSorted[0]]}%
                </div>
                <div>
                    {subMajorElements}
                </div>
                <div className="h-64"></div>
                <button
                    className="w-[117px] h-11 px-[22px] py-3.5 bg-black rounded-[100px] justify-start items-start gap-2.5 inline-flex  hover:bg-blue-700 duration-200   mb-10"
                    onClick={() => {
                        navigate('/after-quiz', {
                            state:
                                {
                                    answers: userAnswers,
                                    result: userResult,
                                    keysSorted: careerKeysSorted
                                }
                        });
                    }}
                >
                    <div className="text-white text-base font-bold font-['Montserrat'] leading-none">
                        Continue
                    </div>
                </button>
            </div>
        </div>
    );
};

export default Tests;
